.b-testimonials {
    padding: var(--testimonial-padding) 0;
    scroll-margin-top: var(--header-height);

    --testimonial-padding: 75px;

    background-color: var(--testimonials-background, var(--color-dark-gray-50));
    overflow: hidden;

    @include media-breakpoint-down(md) {
        --paragraph-margin: 0 0 26px 0;
    }

    @include media-breakpoint-up(md) {
        --testimonial-padding: 138px;

        background-image: var(--testimonials-image);
        background-repeat: no-repeat;
        background-size: auto 100vh;
        background-position: center;
    }

    &__swiper {
        margin-top: 7.6vh;
        padding-bottom: 48px;

        @include wide-small(93px);
    }

    &__slide {
        text-align: center;
        padding: 0 52px 32px 52px;

        @include media-breakpoint-down(md) {
            padding: 0 20px 44px 20px;
        }

        img {
            border-radius: 100px;
            margin-bottom: 16px;
        }
    }

    &__name {
        --paragraph-color: var(--color-dark-gray);

        font-weight: 500;
        font-size: 22px;
        line-height: 1.4;
    }

    &__text {
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
            margin-bottom: 42px;
        }
    }

    &__desc {
        --paragraph-margin: 0;
    }

    &__star {
        height: 48px;
        // background-color: red;

        &-line {
            left: calc(var(--bs-gutter-x) * -1);
            position: absolute;
            top: 23px;
            width: 130%;
            height: 1px;
        }

        &-object {
            position: absolute;
            right: 88px;
        }
    }
}
