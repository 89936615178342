.b-cities {
    $self: &;

    // transition: $global-transition;
    transition: all 0.3s linear;
    position: relative;
    z-index: 5;
    scroll-margin-top: var(--header-height);

    --cities-padding-top: 131px;
    --cities-padding-bottom: 119px;
    --heading-3-margin: 0 0 24px 0;
    --paragraph-color: #{$color-dark-gray-100};
    --paragraph-margin: 0 0 24px 0;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    @include media-breakpoint-down(xl) {
        padding-top: var(--cities-padding-top);
        padding-bottom: var(--cities-padding-bottom);

        --cities-padding-top: 74px;
        --cities-padding-bottom: 70px;
    }

    &__container {
        position: relative;
        z-index: 3;
    }

    &__bg {
        background-image: var(--cities-background-image);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: var(--cities-bg-opactiy, 0);
        visibility: var(--cities-bg-visibility, hidden);
        transition: all 0.3s linear;
    }

    &__content {
        .wp-block-heading {
            transition: all 0.3s linear;
        }

        @include media-breakpoint-down(xl) {
            margin-bottom: 118px;
        }

        @include media-breakpoint-up(xl) {
            position: absolute;
            top: var(--cities-padding-top);
        }
    }

    &__boxs {
        gap: 0;

        @include media-breakpoint-down(xl) {
            gap: 37px;
        }
    }

    &__box {
        position: relative;
        width: 100%;

        @include media-breakpoint-up(xl) {
            height: 100vh;
            margin: 0;
            padding: calc(var(--cities-padding-top) * 2) 0 119px 0;
            width: 25%;
        }

        &::after {
            content: '';
            display: flex;
            z-index: -1;

            @include media-breakpoint-down(xl) {
                background: linear-gradient(
                    90deg,
                    rgba(var(--cities-color-gradient), 0.5) 50%,
                    rgba(var(--cities-color-gradient), 0) 91.01%
                );
                width: calc(100% + (2 * $grid-gutter-width) + 30%);
                margin-left: -$grid-gutter-width;
                margin-top: 45px;
                height: 1px;
            }

            @include media-breakpoint-up(xl) {
                background: linear-gradient(
                    180deg,
                    rgba(var(--cities-color-gradient), 0.5) 50%,
                    rgba(var(--cities-color-gradient), 0) 91.01%
                );
                position: absolute;
                right: 0;
                width: 1px;
                transform: translateX(-39px);
                height: 106vh;
                top: 0;
            }

            @include media-breakpoint-up(xxl) {
                transform: translateX(-82px);
            }
        }

        &--last {
            @include media-breakpoint-down(xl) {
                &::after {
                    display: none;
                }

                #{$self}__star {
                    display: none;
                }
            }
        }
    }

    &__box-content {
        width: 100%;

        h3,
        p,
        .c-btn {
            transition: all 0.3s linear;
        }

        @include media-breakpoint-up(xl) {
            padding: 100px 80px 0 0;
        }

        @include media-breakpoint-up(xxl) {
            padding: 100px 140px 0 0;
        }
    }

    &__star {
        position: absolute;

        @include media-breakpoint-down(xl) {
            bottom: -74px;
            right: 88px;
        }

        @include media-breakpoint-up(xl) {
            right: 16px;
            // top: 12px;
            top: 106px;
        }

        @include media-breakpoint-up(xxl) {
            right: 59px;
        }
    }

    &.active {
        $citeisBg: var(--cities-background-image);

        color: var(--cities-color-hover);

        --cities-bg-opactiy: 1;
        --cities-bg-visibility: visible;
        --paragraph-color: var(--cities-color-hover);
        --btn-color: var(--cities-color-hover);

        transition: all 0.3s linear;

        .b-cities__box-content p {
            text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
        }

        &::after {
            @if $citeisBg {
                opacity: 1;
            } @else {
                opacity: 0;
            }
        }
    }
}
