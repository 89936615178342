.b-text-background {
    background-image: var(--text-bg-image);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: var(--text-bg-padding-top);
    padding-bottom: var(--text-bg-padding-bottom);
    color: var(--text-bg-content);
    scroll-margin-top: var(--header-height);

    --paragraph-color: var(--text-bg-content);

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.66;
        background-color: var(--text-bg-layer);
    }

    --text-bg-padding-top: 207px;
    --text-bg-padding-bottom: 207px;

    @include media-breakpoint-down(lg) {
        --text-bg-padding-top: 133px;
        --text-bg-padding-bottom: 58px;
    }

    &__container {
        z-index: 2;

        &--wide {
            @include wide;
        }
    }

    &__columns {
        --bs-gutter-row: 56px;
    }
}
