.c-box-offer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 16px;
    padding: 32px;
    border-radius: $global-radius;
    background-color: var(--color-white);

    @include media-breakpoint-down(md) {
        padding: 13.5px;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;

        &-wrapp {
            display: block;
            border-radius: 16px;

            @include media-breakpoint-up(md) {
                width: 31%;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 32px;
            }
        }
    }

    &__content {
        @include media-breakpoint-up(md) {
            padding: 20px 0 20px 53px;
            width: 69%;
        }
    }

    &__title {
        margin-bottom: 1px;
        font-size: 40px;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: -0.025em;

        @include media-breakpoint-down(md) {
            font-size: 26px;
        }
    }

    &__street {
        font-size: 22px;
        font-weight: 400;
        line-height: 1.1;
        letter-spacing: -0.025em;

        @include media-breakpoint-down(md) {
            font-size: 19px;
        }
    }

    &__text {
        margin: 48px 0 22px;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.025em;
        color: var(--color-dark-gray-100);
        text-align: justify;

        @include media-breakpoint-down(md) {
            margin-top: 24px;
            font-size: 15px;
        }
    }

    &__footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: calc(var(--bs-gutter-x) * 0.5);

        @include media-breakpoint-down(md) {
            gap: 32px;
        }

        &-col {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    $gap: 27px;

    &__smal-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 46px;
        margin-right: $gap;

        @include media-breakpoint-down(md) {
            font-size: 19px;
            line-height: 1.4;
        }
    }

    &__icons {
        gap: 13.5px $gap;

        @media (max-width: 1840px) {
            gap: 13.5px 20px;
        }
    }

    &__btn {
        width: 100%;
    }
}
