.b-form-extended {
    scroll-margin-top: var(--header-height);

    &__content,
    &__person {
        padding-bottom: 101px;
        padding-top: 101px;
        padding-left: 40px;
        padding-right: 40px;

        > * {
            @include medium;

            position: relative;
            z-index: 5;
        }
    }

    &__content {
        --heading-3-margin: 0 0 16px 0;
    }

    &__person {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__avatar {
        display: flex !important;
        justify-content: center;
        align-items: center;

        img {
            margin-bottom: 40px;
            border-radius: 300px;
        }
    }

    &__name {
        font-weight: 300;
        font-size: 30px;
        line-height: 0.9;

        --paragraph-margin: 0 0 8px 0;
    }

    &__separator {
        max-width: 432px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 17px;
        margin-bottom: 27px;
    }

    &__email,
    &__tel {
        --paragraph-margin: 0;

        > a {
            font-weight: 400;
            font-size: 20px;
            line-height: 156.9%;
            text-decoration: none;
        }
    }

    &__corner {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 50vw;
        height: auto;
        z-index: 1;

        img {
            height: auto;
        }
    }

    &__wrapper-teams {
        position: relative;
        max-width: 100%;
    }

    &__lists {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;

        &-item {
            width: calc(33% - 13px);

            @media screen and (max-width: 1450px) {
                width: calc(50% - 13px);
            }

            @include bp-down(small) {
                width: 100%;
            }
        }
    }
}
