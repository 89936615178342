.b-carousel-tabs {
    $self: &;

    position: relative;
    background-color: var(--carousel-tabs-background);
    scroll-margin-top: var(--header-height);

    --paragraph-color: var(--carousel-tabs-color);

    &__items {
        z-index: 5;
        gap: 0 15px;
        overflow: auto;
        margin-bottom: 54px;
        padding-bottom: 25px;

        @include media-breakpoint-up(md) {
            margin-bottom: 11.9vh;
        }

        @include media-breakpoint-down(xxl) {
            margin-right: calc((var(--bs-gutter-x) / 2) * -1);
        }
    }

    &__item {
        --heading-5-margin: 0 0 7px 0;
        --carousel-tabs-item-background: var(--color-gold-100);
        --icon-color: var(--color-dark-gray);
        --icon-background: var(--color-gold);

        border-radius: $global-radius;
        padding: 24px;
        background-color: var(--carousel-tabs-item-background);
        cursor: pointer;
        transition: $global-transition;
        max-width: 283px;
        flex: 0 0 230px;

        @include media-breakpoint-up(xxl) {
            flex: 0 0 calc(100% / 5);
        }

        &.active {
            #{$self}__item-btn {
                svg {
                    transform: rotate(-90deg);
                }
            }
        }

        &.active,
        &:hover {
            --icon-background: var(--color-white);
            --carousel-tabs-item-background: var(--color-yellow);
        }

        &-btn {
            path,
            circle,
            svg {
                transition: $global-transition;
            }
        }
    }

    &__texts {
        display: none;
        max-height: 0;

        &.active {
            display: flex;
            animation: fade-in 300ms forwards ease-in-out;
            // overflow: hidden;
        }

        @include media-breakpoint-up(md) {
            padding-right: 52px;
        }
    }

    &__text {
        padding-bottom: 48px;
        padding-top: 13.3vh;
        flex-basis: 0;
        flex-grow: 1;
        width: 100%;

        @include media-breakpoint-down(md) {
            padding-top: 60px;
            order: 2;
        }
    }

    &__column {
        flex-basis: 32%;
        flex-grow: 0;

        @include media-breakpoint-down(md) {
            order: 1;
        }
    }

    &__image {
        @include media-breakpoint-down(md) {
            max-width: calc(100% + var(--bs-gutter-x));
            margin-left: calc((var(--bs-gutter-x) / 2) * -1);
            width: calc(100% + var(--bs-gutter-x));
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            right: 0;
            top: 0;
            height: 100% !important;
            width: 32%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include media-breakpoint-down(md) {
                max-height: 447px;
            }
        }
    }

    &__container {
        @include media-breakpoint-up(md) {
            min-height: 100vh;
        }
    }

    &__content {
        padding-bottom: 32px;

        @include media-breakpoint-up(md) {
            columns: 2;
        }
    }
}
