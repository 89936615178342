@mixin wide {
    max-width: 1478px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@mixin wide-small($padding) {
    max-width: calc(1182px + $padding);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@mixin medium {
    max-width: 582px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@mixin small {
    max-width: 462px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
