/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
    $self: &;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0;

    &--footer {
        justify-content: flex-end;
        #{$self}__link {
            font-size: 18px;

            &:not(&--btn) {
                color: $color-gold;

                &:hover {
                    color: $color-white;
                }
            }
        }
    }

    @include bp-down(medium) {
        display: block;
    }

    &__item {
        font-weight: bold;
        list-style: none;
        margin: 0 4px;
        padding: 0;

        &:last-child {
            margin: 0 0 0 4px;

            &.btn {
                margin: 0 0 0 28px;

                @include bp-down(xlarge) {
                    margin: 0 0 0 15px;
                }

                @include bp-down(large) {
                    margin: 0 0 0 8px;
                }

                @include bp-down(medium) {
                    margin: 10px 0 5px 0;
                }
            }
        }

        @include bp-down(medium) {
            margin: 0 0 5px 0;
        }
    }

    &__link {
        display: inline-block;
        font-size: 22px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.025em;
        padding: 10px 16px;
        text-decoration: none;

        &:not(&--btn) {
            color: $color-white;
        }

        &--btn {
            padding: 16px 33px;
            font-weight: 700;
            letter-spacing: -0.025em;
        }

        &:hover {
            color: $color-yellow;
        }

        @include bp-down(xlarge) {
            font-size: 18px;
            padding: 10px 8px;

            &--btn {
                padding: 16px 25px;
            }
        }

        @include bp-down(large) {
            font-size: 15px;
            padding: 5px 4px;

            &--btn {
                padding: 13px 20px;
            }
        }

        @include bp-down(medium) {
            font-size: 18px;
            padding: 10px 4px;

            &--btn {
                padding: 13px 20px;
            }
        }
    }

    // &__dropdown {
    //     display: none;
    //     flex-direction: column;
    //     margin: 0;
    //     position: absolute;

    //     .c-main-nav__item:hover & {
    //         display: flex;
    //     }
    // }

    // &__subitem {
    //     list-style: none;
    //     margin: 0;
    // }

    // &__sublink {
    //     background: $color-white;
    //     border-bottom: 1px solid $color-border;
    //     display: block;
    //     padding: 0.5em;
    //     text-decoration: none;
    // }
}
