.c-mobile-menu {
    $self: &;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transform: translateX(100%);
    transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1),
        visibility 0.1s cubic-bezier(0.4, 0, 0.2, 1), z-index 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0.1s, 1.5s, 1.5s, 1.5s;
    background: $color-middle-black;

    &.is-active {
        visibility: visible;
        opacity: 1;
        z-index: 99999;
        transform: translate(0);
        transition-delay: 0s, 0s, 0s, 0s;
    }

    &__inner {
        width: 100%;
        padding: 22px 18px;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 38px;
        height: 43px;
    }

    &__switcher {
        margin-top: 20px;
    }
}
