/* space-grotesk-latin-ext-300-normal */
@font-face {
    font-family: $font-name;
    font-style: normal;
    font-display: var(--fontsource-display, swap);
    font-weight: 300;
    src: url('~assets/fonts/SpaceGrotesk-Light.ttf') format('truetype');
}

/* space-grotesk-latin-ext-400-normal */
@font-face {
    font-family: $font-name;
    font-style: normal;
    font-display: var(--fontsource-display, swap);
    font-weight: 400;
    src: url('~assets/fonts/SpaceGrotesk-Regular.ttf') format('truetype');
}

/* space-grotesk-latin-ext-500-normal */
@font-face {
    font-family: $font-name;
    font-style: normal;
    font-display: var(--fontsource-display, swap);
    font-weight: 500;
    src: url('~assets/fonts/SpaceGrotesk-Medium.ttf') format('truetype');
}

/* space-grotesk-latin-ext-600-normal */
@font-face {
    font-family: $font-name;
    font-style: normal;
    font-display: var(--fontsource-display, swap);
    font-weight: 600;
    src: url('~assets/fonts/SpaceGrotesk-SemiBold.ttf') format('truetype');
}

/* space-grotesk-latin-ext-700-normal */
@font-face {
    font-family: $font-name;
    font-style: normal;
    font-display: var(--fontsource-display, swap);
    font-weight: 700;
    src: url('~assets/fonts/SpaceGrotesk-Bold.ttf') format('truetype');
}
