/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

:root {
    --heading-2-font-size: 63px;
    --heading-2-line-height: 1.27;
    --heading-2-font-weight: 500;
    --heading-2-margin: 0 0 48px 0;
    --heading-3-font-size: 49px;
    --heading-3-line-height: 1.33;
    --heading-3-font-weight: 400;
    --heading-3-margin: 0 0 48px 0;
    --heading-5-font-size: 22px;
    --heading-5-line-height: 1.18;
    --heading-5-font-weight: 400;
    --heading-5-margin: 0 0 24px 0;

    @media screen and (max-width: 1600px) {
        --heading-2-font-size: 50px;
        --heading-2-line-height: 1.27;
    }

    @include media-breakpoint-down(xl) {
        --heading-2-font-size: 34px;
        --heading-2-line-height: 1.35;
        --heading-2-margin: 0 0 32px 0;
        --heading-3-font-size: 34px;
        --heading-3-line-height: 1.35;
        --heading-3-margin: 0 0 24px 0;
    }

    @media screen and (max-height: map-get($breakpointsHeight, small)) {
        --heading-2-font-size: 34px;
        --heading-2-line-height: 1.35;
        --heading-2-margin: 0 0 32px 0;
        --heading-3-font-size: 34px;
        --heading-3-line-height: 1.35;
        --heading-3-margin: 0 0 24px 0;
    }

    @include media-breakpoint-down(md) {
        --heading-2-font-size: 29px;
        --heading-2-line-height: 1.35;
        --heading-2-margin: 0 0 32px 0;
        --heading-3-font-size: 24px;
        --heading-3-line-height: 1.35;
        --heading-3-margin: 0 0 24px 0;
    }
}

h1,
.h1 {
    font-style: normal;
    font-weight: var(--heading-2-font-weight);
    font-size: var(--heading-2-font-size);
    line-height: var(--heading-2-line-height);
    margin: var(--heading-2-margin);
    color: currentColor;

    // letter-spacing: var(--heading-1-letter-spacing);
}

h2,
.h2 {
    font-style: normal;
    font-weight: var(--heading-2-font-weight);
    font-size: var(--heading-2-font-size);
    line-height: var(--heading-2-line-height);
    margin: var(--heading-2-margin);
    color: currentColor;

    // letter-spacing: var(--heading-2-letter-spacing);
}

h3,
.h3 {
    font-style: normal;
    font-weight: var(--heading-3-font-weight);
    font-size: var(--heading-3-font-size);
    line-height: var(--heading-3-line-height);
    margin: var(--heading-3-margin);
    color: currentColor;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-style: normal;
    font-weight: var(--heading-5-font-weight);
    font-size: var(--heading-5-font-size);
    line-height: var(--heading-5-line-height);
    margin: var(--heading-5-margin);
    color: currentColor;
}

h6 {
    font-size: 1rem;
}
