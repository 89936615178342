.b-form {
    background-color: var(--form-background, var(--color-dark-gray-50));
    padding-top: 88px;
    padding-bottom: 44px;
    scroll-margin-top: var(--header-height);

    --heading-3-margin: 0 0 16px 0;

    @include media-breakpoint-down(xl) {
        --heading-3-margin: 0 0 24px 0;
    }

    &__corner {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 100vw;
        height: auto;
        z-index: 1;

        img {
            height: auto;
        }
    }

    &__content {
        z-index: 2;

        @include small;
    }

    &__form {
        margin-top: 32px;

        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }
    }
}
