/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
:root {
    --btn-default-padding-y: 10.5px;
    --btn-default-padding-x: 32px;
    --btn-default-font-size: 22px;
    --btn-default-font-weight: 700;
    --btn-default-background: var(--color-yellow);
    --btn-default-text: var(--color-brown);
}

.c-btn {
    display: inline-flex;
    text-align: center;
    margin: var(--btn-margin, 0);
    cursor: pointer;
    font-weight: var(--btn-font-weight, var(--btn-default-font-weight));
    font-size: var(--btn-font-size, var(--btn-default-font-size));
    padding: var(--btn-padding, var(--btn-default-padding-y) var(--btn-default-padding-x));
    transition: $global-transition;
    border-radius: var(--btn-radius, 60px);
    background-color: var(--btn-background, var(--btn-default-background));
    color: var(--btn-color, var(--color-brown));
    text-decoration: none;
    box-shadow: none;
    outline: none;
    border: none;
    justify-content: center;
    line-height: inherit;
    font-family: inherit;

    &:hover {
        background-color: var(--btn-background, var(--color-brown));
        color: var(--btn-color, var(--color-yellow));
    }
}

/* Style variants
   ========================================================================== */

// .c-btn--primary {
//   background-color: $color-link;

//   &,
//   &:hover,
//   &:active,
//   &:focus {
//     text-decoration: none;
//     /* [4] */
//     color: $color-white;
//   }

//   &:hover,
//   &:focus {
//     background-color: $color-hover;
//   }
// }

/* Size variants
   ========================================================================== */

// .c-btn--small {
//   padding: 0.25rem 0.5rem;
// }

// .c-btn--large {
//   padding: 0.75rem 1.5rem;
// }
