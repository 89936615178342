.b-gallery-slider {
    padding: 100px 0;
    position: relative;
    background-color: var(--gallery-slider-background, var(--color-dark-gray));
    overflow: hidden;
    scroll-margin-top: var(--header-height);

    @include bp-down(large) {
        padding: 66px 0 53px;
    }

    .wp-block-heading {
        margin-bottom: 32px;
    }

    &__col {
        &--content {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;
            position: relative;
            background-color: var(--gallery-slider-background, var(--color-dark-gray));
            z-index: 2;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 100%;
                height: 100%;
                width: 100%;
                background-color: var(--gallery-slider-background, var(--color-dark-gray));
                z-index: 2;
            }

            @include bp-down(large) {
                margin-bottom: 50px;

                &::after {
                    display: none;
                }
            }
        }

        &--swiper {
            z-index: 1;
        }
    }

    &__swiper {
        overflow: visible;
    }

    &__img {
        width: 100%;
        height: auto;
        border-radius: 16px;

        &-wrapp {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 16px;
        }
    }

    &__slide-main {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(222, 209, 179, 0.5), rgba(222, 209, 179, 0.5));
            border-radius: 16px;
            opacity: 1;
            transition: all 0.5s linear;
        }

        &.swiper-slide-active,
        &.swiper-slide-next {
            &::before {
                opacity: 0;
            }
        }
    }

    &__arrow-swiper {
        @include bp-down(medium) {
            display: none;
        }
    }
}
