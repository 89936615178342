// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius-small: 8px !default;
$global-radius: 24px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */
$font-name: 'Space Grotesk';
$font-sans-serif: $font-name, sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
    small: 480px,
    medium: 768px,
    large: 1024px,
    xlarge: 1280px,
    full: 1600px,
);

$breakpointsHeight: (
    small: 600px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-dark-gray: #1c1a1a;
$color-dark-gray-300: #a4a4a4;
$color-dark-gray-100: #525252;
$color-dark-gray-50: #eaeaea;
$color-gold: #bba980;
$color-gold-100: #ded1b3;
$color-yellow: #e8c575;
$color-brown: #433515;
$color-middle-black: #1d1b1b;
$color-placeholder-input: #a4a4a4;
$color-bg-switcher: #343434;

// CSS variables
:root {
    --font-main: #{$font-sans-serif};
    --font-name: #{$font-name};
    --color-white: #{$color-white};
    --color-black: #{$color-black};
    --color-dark-gray: #{$color-dark-gray};
    --color-dark-gray-300: #{$color-dark-gray-300};
    --color-dark-gray-100: #{$color-dark-gray-100};
    --color-dark-gray-50: #{$color-dark-gray-50};
    --color-gold: #{$color-gold};
    --color-gold-100: #{$color-gold-100};
    --color-yellow: #{$color-yellow};
    --color-brown: #{$color-brown};
    --bs-gutter-row: 0;
    --bs-primary-text-emphasis: #052c65;
    --bs-primary-border-subtle: #cfe2ff;
    --bs-primary-bg-subtle: #cfe2ff;
    --bs-success-text-emphasis: #0a3622;
    --bs-success-bg-subtle: #d1e7dd;
    --bs-success-border-subtle: #a3cfbb;
    --bs-warning-text-emphasis: #664d03;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-text-emphasis: #58151c;
    --bs-danger-bg-subtle: #f8d7da;
    --bs-danger-border-subtle: #f1aeb5;
}

$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;

// Text
$color-bg: $color-white;
$color-text: $color-dark-gray;

// Links
$color-link: $color-medium-grey;
$color-hover: $color-light-grey;

// Borders
$color-border: #d2d2d2;

// Fills
$color-fill: $color-very-light-grey;
