.b-logos {
    padding: 118px 0 141px 0;
    scroll-margin-top: var(--header-height);

    --heading-2-margin: 0 0 32px 0;

    @include media-breakpoint-down(lg) {
        padding: 76px 0 100px 0;
    }

    &__content {
        max-width: 1182px;
        margin-left: auto;
        margin-right: auto;
    }

    &__items {
        gap: 65px;
        margin-top: 73px;

        img {
            max-width: 183px;
            height: auto;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 67px;
            gap: 50px;

            img {
                max-width: 142px;
            }
        }
    }

    &__link {
        &:hover {
            opacity: 0.7;
        }
    }
}
