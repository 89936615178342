.b-icons {
    background-color: var(--icons-background);
    padding-top: 72px;
    padding-bottom: 92px;
    scroll-margin-top: var(--header-height);

    --heading-3-margin: 0 0 56px 0;

    @include media-breakpoint-down(md) {
        padding-top: 52px;
        padding-bottom: 72px;
    }

    $padding: 46px;
    $border: 2px;

    &__box {
        padding: $padding 24px;
        background-color: var(--color-white);
        text-align: center;
        border: $border solid var(--color-gold-100);
        gap: 10px;
        border-radius: $global-radius;
        height: calc(100% - ($border * 2) - ($padding * 2));
        transition: $global-transition;

        &:hover {
            background-color: transparent;
        }
    }

    &__slide {
        height: auto;
    }

    &__icon {
        margin: 0 0 0 0;
    }

    &__title {
        color: var(--color-dark-gray);
        font-weight: 400;
        font-size: 22px;
        line-height: 1.45;
    }

    &__swiper-container {
        @include media-breakpoint-down(md) {
            --bs-gutter-x: 0;
        }
    }
}
