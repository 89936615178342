.b-hero {
    padding-top: var(--text-bg-padding-top);
    padding-bottom: var(--text-bg-padding-bottom);
    color: var(--text-bg-content);
    overflow: hidden;
    background-color: var(--text-bg-layer);

    --paragraph-color: var(--text-bg-content);

    &--static {
        background-image: var(--text-bg-image);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__image {
        position: absolute;
        top: calc(15% - var(--scrollTop, 0%));
        bottom: 0;
        right: 0;
        left: 0;
        max-width: calc(80% + var(--scrollMaxWidth, 0%));
        margin: 0 auto;
        transition: width 300ms ease;
        border-top-left-radius: $global-radius;
        border-top-right-radius: $global-radius;
        text-align: center;

        @include media-breakpoint-down(md) {
            max-width: calc(95% + var(--scrollMaxWidth, 0%));
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: $global-radius;
            border-top-right-radius: $global-radius;
        }

        &.active {
            max-width: 100%;
            top: 0;
            border-radius: 0;

            img {
                border-radius: 0;
            }
        }
    }

    $gap: 46px;

    &__cities {
        // background: rgba(82, 82, 82, 0.92);
        background: rgba(28, 26, 26, 0.74);
        backdrop-filter: blur(10px);
        border-radius: $global-radius-small;
        gap: $gap;
        padding: 24px;

        &--image {
            position: absolute;
            top: -15px;
            right: 21px;
            filter: brightness(0.5);
        }

        @include media-breakpoint-up(lg) {
            padding: 24px 67px 24px 67px;
            max-width: 282px;
            min-height: 386px;
        }

        @include media-breakpoint-down(lg) {
            padding: 55px 20px 35px 20px;
            margin-top: 49px;
        }
    }

    &__city {
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 1;
        color: var(--color-dark-gray-50);
        text-decoration: none;

        &:hover {
            color: var(--color-yellow);
        }

        @include media-breakpoint-down(md) {
            width: calc(50% - $gap);
            font-size: 25px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 22px;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-image: var(--hero-layer-image);
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: center;
        filter: brightness(0.7);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: 1;
        // opacity: 0.66;
        // background: linear-gradient(0deg, rgba(28, 26, 26, 0.5), rgba(28, 26, 26, 0.5));
        opacity: 1;
        background: linear-gradient(0deg, rgba(28, 26, 26, 0.6), rgba(28, 26, 26, 0.6));
    }

    --text-bg-padding-top: 207px;
    --text-bg-padding-bottom: 207px;

    @include media-breakpoint-down(lg) {
        --text-bg-padding-top: 90px;
        --text-bg-padding-bottom: 130px;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        height: 100%;
        // height: 100vh;
    }

    &--cities {
        @include media-breakpoint-down(lg) {
            --text-bg-padding-bottom: 41px;
        }
    }

    &__container {
        z-index: 2;

        &--wide {
            @include wide;
        }
    }

    &__content {
        .wp-block-heading {
            margin-bottom: 22px;

            @include media-breakpoint-down(lg) {
                margin-bottom: 40px;
            }
        }
    }
}
