.b-simple-map {
    position: relative;
    padding: 50px 0;
    background-color: var(--map-background);

    --paragraph-color: var(--map-color);

    &__iframe {
        height: 500px;
        width: 100%;
        border-radius: 20px;
        border: 1px solid $color-yellow;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            height: 350px;
        }

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }
    }
}
