.wpcf7 {
    --form-input-placeholder: #{$color-placeholder-input};

    label {
        display: flex;
        width: 100%;
        margin-bottom: 8px;
    }

    input {
        &[type='submit'] {
            --btn-padding: 14px 32px;

            margin-top: 8px;
            width: 100%;
        }
    }

    .wpcf7-form-control-wrap {
        width: 100%;

        input,
        select,
        textarea {
            font-family: $font-sans-serif;
            font-weight: 400;
            font-size: 17px;
            line-height: 1.47;
            width: 100%;
            background-color: var(--form-input-background, #{$color-white});
            border: 1px solid var(--form-input-border, #{$color-border});
            padding: 15px 16px;
            border-radius: $global-radius-small;
            color: var(--form-input-color, var(--color-dark-gray));
            outline: none;

            &::placeholder {
                color: var(--form-input-placeholder, var(--color-dark-gray-100));
                opacity: 1;
            }
        }

        select {
            appearance: none;
        }

        .placeholder {
            color: var(--form-input-placeholder, var(--color-dark-gray-100));
        }

        // option,
        // select {

        //     &:disabled,
        //     &[value=""] {
        //         color: var(--form-option-disabled, var(--color-dark-gray-300));
        //         background-color: var(--form-option-disabled-bg, var(--color-dark-gray-50));
        //     }
        // }

        textarea {
            max-height: 166px;
            max-width: 100%;
            margin-bottom: 6px;
            margin-top: 10px;
        }
    }

    .custom-select-wrapper {
        display: flex;
        align-items: center;

        &::after {
            display: block;
            position: absolute;
            right: 23px;
            content: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7375 2.36125e-07L8 5.56275L2.2625 7.37713e-07L0.5 1.71255L8 9L15.5 1.71255L13.7375 2.36125e-07Z' fill='%23BBA980'/%3E%3C/svg%3E%0A");
        }
    }

    .wpcf7-acceptance {
        display: inline-flex;

        .wpcf7-list-item {
            margin: 0;

            > label {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }

        .wpcf7-list-item-label {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            font-style: normal;
            color: var(--form-checkbox-color);
            font-family: $font-sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            min-height: 32px;
            margin: 0;
            position: relative;
            padding: 0 0 0 0;
            transition: $global-transition;
            border: 1px solid transparent;
            cursor: pointer;

            --form-checkbox-border-radius: #{$global-radius-small};
            --form-checkbox-border-color: #{$color-border};
            --form-checkbox-color: #{$color-dark-gray-100};
            --form-checkbox-background: #{$color-white};
            --form-checkbox-size: 32px;

            @include media-breakpoint-down(md) {
                padding: 0 0 0 0;
            }

            &::before {
                content: '';
                flex-shrink: 0;
                width: var(--form-checkbox-size);
                height: var(--form-checkbox-size);
                background-color: var(--form-checkbox-background);
                border: 1px solid var(--form-checkbox-border-color);
                border-radius: var(--form-checkbox-border-radius);
                display: inline-flex;
                // position: absolute;
                // left: 0;
                // top: 0;
                margin-right: 13px;
                justify-content: center;
                align-items: center;
                transition: $global-transition;
                background-size: var(--form-checkbox-size) var(--form-checkbox-size);
                background-repeat: no-repeat;
                background-position: center;
            }

            a {
                color: var(--form-checkbox-color, var(--color-dark-gray));
                text-decoration: none;
                font-weight: 500;
            }
        }

        &.wpcf7-not-valid {
            .wpcf7-list-item-label {
                &::before {
                    border-color: #dc3232;
                }
            }
        }

        input[type='checkbox'] {
            display: none;

            &:checked + span {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='3.5' fill='%23525252'/%3E%3Cpath d='M18.875 7.625L10.125 16.375L5.75 12' stroke='%23FBFBFB' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='3.5' stroke='%23525252'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    // Custom notify style
    &-form {
        .wpcf7-spinner {
            position: absolute;
            bottom: -12px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        .wpcf7-not-valid-tip {
            position: absolute;
            top: 35px;
            right: 10px;
        }

        .wpcf7-acceptance + .wpcf7-not-valid-tip {
            position: relative;
            top: 0;
            right: 0;
            line-height: 1.1;
            padding-left: 46px;
        }

        .wpcf7-response-output {
            font-weight: 500;
            font-size: 14px;
            margin: 16px 0 16px !important;
            padding: 16px 32px !important;
            border: 1px solid var(--bs-primary-border-subtle) !important;
            color: var(--bs-primary-text-emphasis) !important;
            background-color: var(--bs-primary-bg-subtle) !important;

            /* Blue */
        }

        &.sent .wpcf7-response-output {
            border-color: var(--bs-success-border-subtle) !important;
            color: var(--bs-success-text-emphasis) !important;
            background-color: var(--bs-success-bg-subtle) !important;

            /* Green */
        }

        &.failed .wpcf7-response-output,
        &.aborted .wpcf7-response-output {
            border-color: var(--bs-danger-border-subtle) !important;
            color: var(--bs-danger-text-emphasis) !important;
            background-color: var(--bs-danger-bg-subtle) !important;

            /* Red */
        }

        &.spam .wpcf7-response-output,
        &.invalid .wpcf7-response-output,
        &.unaccepted .wpcf7-response-output,
        &.payment-required .wpcf7-response-output {
            border-color: var(--bs-warning-border-subtle) !important;
            color: var(--bs-warning-text-emphasis) !important;
            background-color: var(--bs-warning-bg-subtle) !important;
        }
    }
}

.hidden-option {
    display: none;
    opacity: 0;
    font-size: 0;
    visibility: hidden;
}
