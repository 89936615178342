:root {
    --paragraph-margin: 0 0 16px 0;
    --paragraph-font-size: 17px;
    --paragraph-line-height: 1.47;
    --paragraph-font-weight: 400;
    --paragraph-color: var(--color-dark-gray-100);
}

p {
    font-style: normal;
    font-weight: var(--paragraph-font-weight);
    font-size: var(--paragraph-font-size);
    line-height: var(--paragraph-line-height);
    margin: var(--paragraph-margin);
    color: var(--paragraph-color);
}

.o-set-color-child {
    p {
        color: inherit;
    }
}

.text-justify {
    text-align: justify;
}
