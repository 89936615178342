/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
    position: relative;
    overflow: hidden;
    padding: 144px 0 110px;
    background-color: $color-dark-gray;

    @include bp-down(medium) {
        padding: 50px 0 220px;
    }

    &__bg-img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        @media (max-width: 1700px) {
            height: 100%;
            object-fit: cover;
            object-position: right top;
            max-width: 350px;
        }

        @include bp-down(large) {
            max-width: 250px;
            left: -133px;
        }

        &--mobile {
            display: none;
        }

        @include bp-down(medium) {
            top: auto;
            left: auto;
            max-width: 100%;
            bottom: 0;

            &--desktop {
                display: none;
            }

            &--mobile {
                display: block;
                max-width: 100%;
                height: auto;
            }
        }
    }

    &__container {
        position: relative;
        z-index: 2;
    }

    &__row {
        &--top {
            margin-bottom: 50px;
        }
    }

    &__col {
        @include bp-down(medium) {
            &--menu-cities {
                order: 1;
                margin-bottom: 25px;
            }

            &--data {
                order: 2;
            }

            &--menu-normal {
                display: none;
            }
        }
    }

    &__logo {
        display: inline-block;
        max-width: 218px;

        &-wrapper {
            margin-bottom: 40px;
        }
    }

    &__copyright {
        font-size: 15px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: $color-gold;
    }

    &__contact-data {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: $color-white;

        @include bp-down(large) {
            flex-wrap: wrap;
        }

        &-col {
            width: 49%;

            @include bp-down(large) {
                width: 100%;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-item {
            display: block;
            margin-bottom: 4px;

            &--email {
                @include bp-down(xlarge) {
                    font-size: 16px;
                }

                @include bp-down(large) {
                    font-size: 20px;
                }
            }
        }
    }

    &__title-city {
        display: block;
        margin: 10px 0 34px;
        font-size: 17px;
        line-height: 25px;
        letter-spacing: -0.025em;
        color: $color-dark-gray-50;

        @include bp-down(medium) {
            margin: 0 0 34px;
        }
    }

    &__cities-menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;

        &-item {
            width: 49%;
            margin-bottom: 25px;

            @include bp-down(medium) {
                width: 100%;
            }
        }
    }
}
