:root {
    --swiper-pagination-bullet-width: 16px;
    --swiper-pagination-bullet-height: 16px;
    --swiper-pagination-bullet-inactive-color: var(--color-gold-100);
    --swiper-pagination-color: var(--color-gold);
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 8px;
    --swiper-pagination-bottom: 0;
    --swiper-navigation-color: var(--color-gold);
    --swiper-navigation-top-offset: 10%;
    --swiper-navigation-sides-offset: 0;

    @include media-breakpoint-down(md) {
        --swiper-navigation-top-offset: 70%;
    }
}

.swiper-button-prev::after {
    content: url("data:image/svg+xml,%3Csvg width='26' height='41' viewBox='0 0 26 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3175 36.1825L9.66917 20.5L25.3175 4.8175L20.5 0L0 20.5L20.5 41L25.3175 36.1825Z' fill='%23BBA980'/%3E%3C/svg%3E%0A");
}

.swiper-button-next::after {
    content: url("data:image/svg+xml,%3Csvg width='26' height='41' viewBox='0 0 26 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.11709e-07 4.8175L15.6483 20.5L-1.83031e-06 36.1825L4.8175 41L25.3175 20.5L4.8175 -2.74584e-06L9.11709e-07 4.8175Z' fill='%23BBA980'/%3E%3C/svg%3E%0A");
}

.swiper-button {
    &::after {
        transition: all 0.2s linear;
    }

    &.centered {
        top: 50%;
        transform: translateY(25%);
    }

    &.white {
        &.swiper-button-prev {
            &::after {
                content: url("data:image/svg+xml,%3Csvg width='26' height='41' viewBox='0 0 26 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3175 36.1825L9.66917 20.5L25.3175 4.8175L20.5 0L0 20.5L20.5 41L25.3175 36.1825Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
            }

            &:hover::after {
                content: url("data:image/svg+xml,%3Csvg width='26' height='41' viewBox='0 0 26 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3175 36.1825L9.66917 20.5L25.3175 4.8175L20.5 0L0 20.5L20.5 41L25.3175 36.1825Z' fill='%23BBA980'/%3E%3C/svg%3E%0A");
            }
        }

        &.swiper-button-next {
            &::after {
                content: url("data:image/svg+xml,%3Csvg width='26' height='41' viewBox='0 0 26 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.11709e-07 4.8175L15.6483 20.5L-1.83031e-06 36.1825L4.8175 41L25.3175 20.5L4.8175 -2.74584e-06L9.11709e-07 4.8175Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
            }

            &:hover::after {
                content: url("data:image/svg+xml,%3Csvg width='26' height='41' viewBox='0 0 26 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.11709e-07 4.8175L15.6483 20.5L-1.83031e-06 36.1825L4.8175 41L25.3175 20.5L4.8175 -2.74584e-06L9.11709e-07 4.8175Z' fill='%23BBA980'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &.swiper-button-disabled {
        display: none;
    }
}

// position: absolute;
// top: var(--swiper-navigation-top-offset, 50%);
// width: calc(var(--swiper-navigation-size) / 44 * 27);
// height: var(--swiper-navigation-size);
// margin-top: calc(0px - var(--swiper-navigation-size) / 2);
