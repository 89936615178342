.c-not-found-inf {
    position: relative;
    padding: 20px;
    background: $color-white;
    border-radius: 20px 30px;
    border: 1px solid $color-yellow;

    &__header {
        font-size: 30px;
    }
}
