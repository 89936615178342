.l-filtered-content {
    position: relative;

    &__loader {
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: calc(100% - 70px);
        background: rgb(234, 234, 234, 0.76);
        z-index: 999;

        &--extend {
            top: 190px;
            height: calc(100% - 190px);

            &-search {
                top: 130px;
                height: calc(100% - 130px);
            }
        }

        .c-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            div {
                background: $color-yellow;
            }
        }
    }

    &__btn-wrapper {
        margin: 30px 0;
    }

    &__col {
        &--author {
            margin-bottom: 20px;
        }
    }
}
