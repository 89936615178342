.c-box-member {
    position: relative;

    &__img {
        width: 100%;
        height: auto;

        &-wrapp {
            position: relative;
            display: block;
        }
    }

    &__inf-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: rgba($color-black, 0.9);
        opacity: 0;
        transition: all 0.3s linear;
    }

    &__inf-text {
        margin: 5px 0;
        font-size: 16px;
        color: $color-white;

        &--link {
            line-height: 1;
        }
    }

    &__link {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        font-size: 15px;
        text-decoration: none;
        color: $color-gold;

        svg path {
            transition: $global-transition;
        }

        &:hover {
            color: $color-yellow;

            svg path {
                fill: $color-yellow;
            }
        }
    }

    &__footer {
        padding: 20px 0;
    }

    &__name {
        font-size: 20px;
        color: $color-white;
    }

    &:hover {
        .c-box-member__inf-content {
            opacity: 1;
        }
    }
}
