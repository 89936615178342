.c-filters {
    padding-bottom: 20px;
    margin-bottom: 20px;

    &__header {
        margin-bottom: 16px;
    }

    &__group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        &--top-row {
            margin-bottom: 40px;

            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }
        }

        &--bottom-row {
            margin-top: 22px;
        }

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
        }

        &-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 16px;

            &--gap-biger {
                gap: 30px;

                @include media-breakpoint-down(md) {
                    gap: 16px;
                }
            }

            @include media-breakpoint-down(lg) {
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: stretch;
                width: 100%;
            }
        }
    }

    &__single {
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
}
