.c-menu-toggle {
    $self: &;

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 8px 0 8px 0;
    background-color: transparent;
    border: none;
    z-index: 1;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &.active {
        #{$self}__icon {
            &--open {
                opacity: 0;
            }

            &--close {
                opacity: 1;
            }
        }
    }

    &__text {
        font-family: $font-sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.025em;
        color: $color-white;
    }

    &__burger {
        position: relative;
        display: inline-block;
        margin-left: 10px;
        line-height: 1;
        width: 24px;
        height: 24px;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: $global-transition;

        &--close {
            opacity: 0;
        }

        &--open {
            opacity: 1;
        }
    }
}
