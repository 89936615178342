/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable no-descending-specificity  */
.c-language-custom-wmpl {
    $radius-swticher: 2px;

    width: 52px;
    border-radius: $radius-swticher;
    background-color: $color-bg-switcher;

    .wpml-ls-legacy-dropdown-click {
        a {
            border: none;
            padding: 2px 20px 2px 2px;
            background-color: $color-bg-switcher;

            &:hover,
            &:focus {
                background-color: $color-bg-switcher;
            }

            &.wpml-ls-item-toggle {
                height: 100%;
                padding: 2px 20px 2px 2px;
                background-color: $color-bg-switcher;
                border: none;
                border-radius: $radius-swticher;

                &::after {
                    content: '';
                    padding: 0;
                    border: none;
                    width: 8px;
                    height: 5px;
                    top: 50%;
                    right: 6px;
                    transform: translateY(-50%);
                    background: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.0725 0.643564L0.1175 0.69828L3.5175 4.77592C3.6325 4.91402 3.805 5 3.9975 5C4.19 5 4.3625 4.91141 4.4775 4.77592L7.875 0.706097L7.9325 0.638353C7.975 0.573215 8 0.49505 8 0.411673C8 0.184992 7.815 0 7.585 0H0.415C0.185 0 0 0.184992 0 0.411673C0 0.497655 0.0275 0.578426 0.0725 0.643564Z' fill='%23EAEAEA'/%3E%3C/svg%3E%0A")
                        no-repeat center;
                    background-size: cover;
                }

                &.active {
                    &::after {
                        top: calc(50% - 2.5px);
                        transform: rotate(180deg);
                    }
                }

                &:hover,
                &:focus {
                    background-color: $color-bg-switcher;
                }
            }
        }

        .wpml-ls-sub-menu {
            border-top: 0;
            transition: none;
            border-radius: 0 0 $radius-swticher $radius-swticher;
            overflow: hidden;

            .wpml-ls-item {
                &:last-child {
                    a,
                    .wpml-ls-link {
                        border-radius: 0 0 $radius-swticher $radius-swticher;
                    }
                }
            }

            a,
            .wpml-ls-link {
                width: auto;
                display: inline-block;
                padding: 2px;
                transition: none;
            }
        }
    }

    .wpml-ls-flag {
        width: 30px;
        height: auto;
        border-radius: $radius-swticher;
    }

    &:hover,
    &:focus {
        background-color: $color-bg-switcher;
    }
}

/* stylelint-enable selector-no-qualifying-type */
/* stylelint-enable selector-max-compound-selectors */
/* stylelint-enable no-descending-specificity  */
