.l-archive {
    &__wrapper-content {
        position: relative;
        padding: 90px 0;
        background-color: var(--cities-background, var(--color-dark-gray-50));
    }

    &__title-main {
        margin-bottom: 25px;
    }

    &__filters-form {
        margin-bottom: 35px;
    }
}
