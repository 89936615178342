@keyframes fade-in {
    from {
        max-height: 0;
        opacity: 0;
    }

    to {
        opacity: 1;
        max-height: 100%;
    }
}
