.c-filter-field {
    $self: &;

    position: relative;
    border-radius: 10px;
    border: 1px solid $color-yellow;
    background-color: $color-yellow;

    &--active {
        #{$self}__content {
            display: block;
        }

        #{$self}__header {
            &::before {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &--type {
        &-industries {
            min-width: 150px;
        }

        &-category {
            min-width: 140px;
        }
    }

    &__header {
        position: relative;
        margin-bottom: 0;
        padding: 13.5px 42px 13.5px 20px;
        color: var(--btn-default-text);
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.65px;
        line-height: 1;
        cursor: pointer;
        white-space: nowrap;

        &--simple-text {
            padding: 0;
            display: block;

            &::before {
                display: none;
            }
        }

        &.active {
            #{$self}__header-label {
                opacity: 1;
            }
        }

        &-label {
            position: absolute;
            top: -6px;
            left: 16px;
            padding: 1px 5px;
            font-size: 10px;
            font-weight: 500;
            line-height: 1;
            letter-spacing: 0.5px;
            background-color: var(--color-dark-gray-50);
            border-radius: 0 0 4px 3px;
            text-transform: none;
            opacity: 0;
            transition: $global-transition;
        }

        &::before {
            content: '';
            width: 16px;
            height: 16px;
            background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.48008 7.50951L3.12257 4.81521C2.84448 4.49739 3.07018 4 3.49249 4L8.20751 4C8.62981 4 8.85552 4.49739 8.57743 4.81521L6.21992 7.50951C6.02408 7.73332 5.67592 7.73332 5.48008 7.50951Z' fill='%23333333'/%3E%3C/svg%3E%0A")
                no-repeat center;
            background-size: cover;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }
    }

    &__content {
        display: none;
        margin: 4px 0;
        position: absolute;
        top: 100%;
        left: -1px;
        right: -1px;
        z-index: 99;
        list-style: none;
        border: 1px solid $color-yellow;
        border-radius: 6px;
        background: $color-white;
        padding: 0;
        max-height: 215px;
        overflow: auto;
    }

    &__input {
        &--radio {
            display: none;
        }

        &--text {
            display: block;
            width: 100%;
            margin: 0;
            border: 0;
            padding: 10px 20px;
            font-size: 13px;
            line-height: 1;
            font-weight: 500;
            letter-spacing: 0.65px;

            &::placeholder {
                color: rgba(0, 0, 0, 0.3);
            }
        }

        &:focus {
            outline: none;
        }
    }

    &__label {
        display: block;
        padding: 10px;
        font-size: 15px;
        line-height: 1;
        font-weight: 500;
        color: $color-brown;
        cursor: pointer;
        transition: $global-transition;

        &--with-icon {
            padding: 5px 10px;
        }

        &:hover {
            color: $color-yellow;
        }
    }

    &__text {
        &--with-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
        }
    }

    /* eslint-disable-next-line no-descending-specificity */
    &__input--radio:checked ~ &__text {
        color: $color-yellow;
    }

    &__icon {
        width: 30px;
        height: auto;
    }
}
