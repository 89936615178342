/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
    color: $color-link;
    text-decoration: none;
    transition: $global-transition;

    &:hover {
        color: $color-hover;
    }
}

.c-link {
    color: $color-white;
    text-decoration: none;

    &:hover {
        color: $color-gold;
    }

    &--gray-big {
        font-size: 37px;
        font-weight: 300;
        line-height: 1;
        letter-spacing: -0.025em;
        color: $color-dark-gray-100;

        @include bp-down(large) {
            font-size: 30px;
        }

        &:hover {
            color: $color-white;
        }
    }
}
