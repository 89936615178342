.b-text-image {
    background-color: var(--text-image-background);
    scroll-margin-top: var(--header-height);

    &__image {
        @media (max-width: 781px) {
            order: 1;
        }

        .wp-block-image {
            @media (max-width: 781px) {
                img {
                    max-width: calc(100% + var(--bs-gutter-x));
                    width: calc(100% + var(--bs-gutter-x));
                    max-height: 362px;
                    object-fit: cover;
                    margin-left: calc((var(--bs-gutter-x) / 2) * -1);
                }
            }
        }
    }

    &__content {
        padding-top: 71px;
        padding-bottom: 80px;

        @media (max-width: 781px) {
            order: 2;
        }

        @include media-breakpoint-up(md) {
            padding-right: 52px;
        }

        @include media-breakpoint-up(xxl) {
            padding-right: 0;
        }
    }

    .is-style-absolute-right {
        @media (min-width: 781px) {
            position: absolute;
            right: 0;
            top: 0;
            height: 100% !important;
            width: 32%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
