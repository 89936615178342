.u-h-100 {
    height: 100%;
}

.u-h {
    &-md {
        &-100v {
            @include media-breakpoint-up(md) {
                min-height: 100vh;
            }

            // @media screen and (max-height: map-get($breakpointsHeight, small)) {
            //     min-height: 100vh;
            //     height: auto;
            // }
        }
    }
}
