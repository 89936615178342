/* ==========================================================================
   #HEADER
   ========================================================================== */
:root {
    --header-height: 127px;

    @include bp-down(large) {
        --header-height: 90px;
    }

    @include bp-down(small) {
        --header-height: 72px;
    }
}

.c-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    // background: $color-middle-black;
    background: linear-gradient(180deg, #303030 -119.29%, rgba(0, 0, 0, 0) 67.4%);
    z-index: 999999;
    transition: $global-transition;

    &.fixed {
        background: $color-middle-black;
    }

    &.is-active {
        background: $color-middle-black;
        transition-delay: 500ms;
    }

    &__row {
        align-items: center;
        height: var(--header-height);
    }

    &__col {
        &--mobile-switcher {
            display: none;
            text-align: right;

            @include bp-down(medium) {
                display: block;
            }
        }

        &--menu-desktop {
            @include bp-down(medium) {
                display: none;
            }
        }
    }

    &__logo {
        display: block;

        @include bp-down(large) {
            max-width: 150px;
        }

        @include bp-down(small) {
            max-width: 145px;
        }
    }

    &__wrap-log-switch {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &__btn-menu {
        display: inline-block;
    }

    &__switcher {
        display: inline-block;
        margin-left: 100px;

        @include bp-down(xlarge) {
            margin-left: 30px;
        }

        @include bp-down(large) {
            margin-left: 10px;
        }

        @include bp-down(medium) {
            display: none;
        }
    }
}
