@use 'sass:map';

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1300px,
    xxl: 1840px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1816px,
);

$grid-columns: 12;
$grid-gutter-width: 40px;
$grid-row-columns: 6;

$contentSize: map.get($container-max-widths, 'xxl');

:root {
    --content-size: #{$contentSize};
}
